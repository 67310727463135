
import { defineComponent, ref, reactive } from "vue";
import XLSX from "xlsx";

export default defineComponent({
  name: "kt-search-and-filter",
  props: {
    listInitial: Array,
    filters: Array,
    ExcelFilename: String,
    ExcelFormat: Object,
    widthDecalage: String,
    searchKeepParent : Object,
    searchDefault: String,
    classSearch: String,
    classSelect: String,
    classGroupSelect: String,
    searchBar: Number,
  },
  emits: ["returnSearch", "returnOpenSub", "returnLoadingDatatable"],

  setup(props, { emit }) {
    const listInit = ref(props.listInitial);

    const state = reactive({
      initialMyList: [],
      myList: [],
      search: props.searchDefault ? props.searchDefault : "",
      loadingDatatable: 0,
      valueOption: [],
      optionsDem: [] as any,
      AllFilters: [] as any,
    });

    const sExcelFilename = props.ExcelFilename as any;
    const sExcelFormat = props.ExcelFormat as Array<1>;

    const xWidthDecalage = props.widthDecalage ? props.widthDecalage : 0;

    const xClassGroupSelect = props.classGroupSelect ? props.classGroupSelect : 'd-flex justify-content-end align-items-center';
    const xClassSelect = props.classSelect ? props.classSelect : 'mx-0 w-275px text-primary me-3';

    const zSearchKeepParent = props.searchKeepParent ? props.searchKeepParent : {};

    const zsearchBar =  props.searchBar ? props.searchBar : 1;

    const myPropsFilter = props.filters as any;
    state.initialMyList = listInit as any;
    // state.myList = listInit as any;


    const searchAllItems = async () => {
      state.myList = state.initialMyList;

      if (state.search.length > 0) {     
        const resultSearch = state.initialMyList.filter(
          (item: any) =>
            String(JSON.stringify(item))
              .toLowerCase()
              .indexOf(state.search.toLowerCase()) >= 0
        );

        if(zSearchKeepParent.parent) {

          const newResult: string[] = [];
          resultSearch.forEach((item) => {

            if (item[zSearchKeepParent.enfant] != item[zSearchKeepParent.parent]) {

                const parentItem = state.initialMyList.find(
                  (x) => x[zSearchKeepParent.enfant] === item[zSearchKeepParent.parent]
                );
                if(parentItem){
                  const tstExist: any = newResult.find(
                    (x) => {
                      const myParentItem: any = parentItem;
                      return x[zSearchKeepParent.enfant] === myParentItem[zSearchKeepParent.parent]
                    }
                  );
                  // if (parentItem && parentItem['displayrow']) parentItem['displayrow'] = 0;
                  if (!tstExist) {
                    newResult.push(parentItem);
                    if (newResult[newResult.length-1]['opensub'] == 0 ) newResult[newResult.length-1]['opensub'] = 1;
                  } 
                } 
            }
                newResult.push(item);
                if (newResult[newResult.length-1]['displayrow'] == 2 ) newResult[newResult.length-1]['displayrow'] = 1;

          });            
          const myNewResult: any = newResult;
          state.myList = myNewResult;
        } else {
          state.myList = resultSearch;
        }


      }
      let strStatus = "";

      if (myPropsFilter.length > 0) {

        for (var i = 0; i < state.AllFilters.length; i++) {
          const KeyProps = myPropsFilter[i].key;
          strStatus = "/";
          // console.log('Filtre',  state.AllFilters[i].vModel);
          if ( state.AllFilters[i].vModel) {
            state.AllFilters[i].vModel.forEach(
              (element) => (strStatus = strStatus + element + "/")
            );
          }


          if (strStatus != "/") {
            const resultSearch = state.myList.filter(
              (item: any) =>
                String(strStatus).indexOf("/" + item[KeyProps] + "/") >= 0
            );
            state.myList = resultSearch;
          }
        }
      }
      emit("returnSearch", state.myList);
      state.loadingDatatable = state.loadingDatatable + 1;
      emit("returnLoadingDatatable", state.loadingDatatable);
      // emit("returnOpenSub", 1);
    };

    let SearchDirect = 0;

    if (myPropsFilter) {
      
      for (var i = 0; i < myPropsFilter.length; i++) {
        state.AllFilters.push({
          title: myPropsFilter[i].name,
          vModel: "",
          vOptions: [],
        });

        for (var j = 0; j < state.initialMyList.length; j++) {
          const KeyProps = myPropsFilter[i].key;
          const ValueProps = myPropsFilter[i].label;
          const myTable = state.initialMyList[j] as any;
          const myKey = myTable[KeyProps];
          const myLabel = myTable[ValueProps];
          const TableSeach =
            state.AllFilters[state.AllFilters.length - 1].vOptions;
          const index = TableSeach.findIndex(
            (object) => object.value === myKey
          );
          if (index === -1) {
            state.AllFilters[state.AllFilters.length - 1].vOptions.push({
              value: myKey,
              label: myLabel,
            });
          }
        }

        for (j = 0; j < state.AllFilters.length; j++) {
          const arraySort = state.AllFilters[j].vOptions.sort(function(a, b) {
            //console.log("sort", b.label);
            return stringCompare(a.label,b.label);
            //return a.label.localeCompare(b.label);
          });
          state.AllFilters[j].vOptions = arraySort;
          //console.log(state.AllFilters[j]);
        }


        if (myPropsFilter[i].default) {
          state.AllFilters[i].vModel = myPropsFilter[i].default;
          SearchDirect = 1;
        }
        

      }

    }

    if(state.search.length > 0 ) SearchDirect = 1;

    if(SearchDirect) {
        searchAllItems();
    }

    const ReturnExcel = () => {
      const tableArray = [] as any;

      const allList =
        state.loadingDatatable === 0 ? state.initialMyList : state.myList;
      for (var j = 0; j < allList.length; j++) {
        tableArray.push({});
        for (var key in sExcelFormat) {
          var value = sExcelFormat[key];
          tableArray[j][key] = allList[j][value];
        }
      }
      const data = XLSX.utils.json_to_sheet(tableArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `${sExcelFilename}.xlsx`);
    };

    function stringCompare(a, b) {
      const collator = new Intl.Collator();
      return collator.compare(a, b);
    }
        

    return {
      listInit,
      state,
      searchAllItems,
      sExcelFilename,
      ReturnExcel,
      xWidthDecalage,
      xClassGroupSelect,
      xClassSelect,
      zsearchBar
    };
  },
});
