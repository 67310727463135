<template>
  <transition name="fade">
      <el-row :gutter="10" class="mt-10" style="width: 100%">
        <el-col :span="24">

          <div class="card" v-if="!state.loaderEnabled" style="width: 100%;">
            <div class="card-header border-0 pt-6">
              <div class="card-title" style="width:100%;">
                <h2>{{$t("Reservations effectuées par les utilisateurs")}}</h2>
              </div>
              <SearchAndFilter
                :listInitial="state.initialMyList"
                :filters="tableFilters"
                @returnSearch="state.myList = $event"
                @returnLoadingDatatable="state.loadingDatatable = $event"
                ExcelFilename="StillOnStockReservation"
                :ExcelFormat="ExcelFormat"
                ></SearchAndFilter>
            </div>
            <div class="card-body pt-0">
              <div
                v-if="state.myList.length == 0"
                class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
              >
                <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                  <inline-svg src="media/icons/duotune/general/gen048.svg" />
                </span>
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
                  <span>{{$t("Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.")}}</span>
                </div>
              </div>
              <Datatable
                      v-if="state.myList && state.myList.length > 0"
                      :table-data="state.myList"
                      :table-header="tableHeader"
                      :enable-items-per-page-dropdown="true"
                      :key = "state.loadingDatatable"
                      :empty-table-text="$t('Aucune recherche effectuée')"
                      class="table-very-small-td"
                    >
                    <template v-slot:cell-uti_c_prenom="{ row: data }"> 
                        {{ data.uti_c_prenom }} {{ data.uti_c_nom }}
                    </template>
                    <template v-slot:cell-uti_c_mail="{ row: data }">
                      <div class="fs-7 text-gray-500">
                        <div>{{ data.uti_c_phone }}</div>
                        <div class="text-blue">{{ data.uti_c_mail }}</div>
                      </div>
                    </template>
                    <template v-slot:cell-uti_c_societe="{ row: data }">
                      {{ data.uti_c_societe }}<br />
                      <span class=" text-gray-400" v-if="data.prenom_am">{{$t("AM Still':")}} {{ data.prenom_am }}</span>
                    </template>
                    <template v-slot:cell-his_d_creation="{ row: data }">
                      {{ data.his_d_creation }}<br />
                    </template>
                    <template v-slot:cell-his_c_json_histo="{ row: data }">
                      <a class="btn btn-sm btn-custom-white mx-1" @click='affHisto(data)'>
                        <span class="svg-icon svg-icon-3 svg-icon-info">
                          <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                          {{$t("Afficher la réservation")}}
                      </a>
                    </template>
                    
              </Datatable>

            </div>
          </div>

          <el-dialog v-model="state.histo" :title="titleDialog()" width="800">
            <span v-if="state.initiallHisto[0].his_c_commentaire" style="font-size: 17px;">{{$t("Commentaire :")}} {{ state.initiallHisto[0].his_c_commentaire }}</span>

            <Datatable
                v-if="state.lHisto && state.lHisto.length > 0"
                :table-data="state.lHisto"
                :table-header="tableHeader2"
                :key="state.reloadHisto"
                :enable-items-per-page-dropdown="true"
                :rows-per-page="50"
                :empty-table-text="$t('Aucun historique à afficher.')"
                class="table-small-td"
              >
              <template v-slot:cell-ref="{ row: data }">
                {{ data.ref }} <br>
                <span class=" text-gray-400" v-if="data.desc">{{ data.desc }}</span>
              </template>
              <template v-slot:cell-qte="{ row: data }">
                {{ data.qte }}
              </template>
              
              </Datatable>
              <div v-else class="d-flex flex-column">
                <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
                <span>{{$t("Il n'existe aucun historique de recherche sur cet utilisateur.")}}</span>
              </div>

            <template #footer>
              <span class="dialog-footer">
                <el-button class="btn btn-primary" @click="state.histo = false">{{$t("Fermer")}}</el-button>
              </span>
            </template>

          </el-dialog>


        </el-col>
      </el-row>
  </transition>
  
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchAndFilter from "@/views/still/search_and_filters_old.vue";

import * as moment from "moment";

import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
  },

  emits : ["displayReservation"],

  setup() {
    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
      lHisto : [] as any, 
      reloadHisto : 1,
      histo: false,
      initiallHisto: [] as any,
      titre: "",
    });
    const tableHeader = ref([
      { name: "Prénom Nom", key: "uti_c_prenom", sortable: false,},
      { name: "Coordonnées", key: "uti_c_mail", sortable: false,},
      { name: "Société", key: "uti_c_societe", sortable: false,},
      { name: "Date de réservation", key: "his_d_creation", sortable: false,},
      { name: "Réservation", key: "his_c_json_histo", sortable: false,},
      { name: "", key: "uti_n_seq", sortable: false,},
    ]);

    const tableHeader2 = ref([
    { name: "Référence", key: "ref", sortable: false,},
    { name: "Quantité", key: "qte", sortable: false,},
    { name: "", key: "id", sortable: false,},

  ]);

    const tableFilters = ref([

      { name: "utilisateur", key: "uti_c_prenom", label: "uti_c_prenom", },
      { name: "AM", key: "prenom_am", label: "prenom_am", },
      { name: "Société", key: "uti_c_societe", label: "uti_c_societe", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang + " HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const ExcelFormat = ref({
      "Prénom": "uti_c_prenom",
      "Nom": "uti_c_nom",
      "Tel": "uti_c_phone",
      "Mail": "uti_c_mail",
      "Creation": "uti_d_import",
      "Société": "uti_c_societe",
      "AM Still": "prenom_am",
      "Reservation": "his_c_json_histo",
      "Date": "his_d_creation",
    });

    onMounted(async () => { 

      let reponse = await getAxios("/getAllUserReserv");
      state.myList = reponse.results;
      console.log(state.myList);
      

      state.initialMyList = reponse.results;
      state.zUser = state.myList[0];

      state.loaderEnabled = false;
      
    });

    const affHisto = async (data) => {
      let myListHisto = await getAxios("/getAllUserReservDetails/" + data.his_n_seq);
      state.initiallHisto = myListHisto.results;
      state.lHisto = JSON.parse(state.initiallHisto[0].his_c_json_histo);
      state.reloadHisto += 1;
      state.histo = true;
      state.titre = t("Reservation faite par") + " " + state.initiallHisto[0].uti_c_prenom + " " + state.initiallHisto[0].uti_c_nom;
  }

  const titleDialog =() => {
    return state.titre;
  }

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      ExcelFormat,
      affHisto,
      tableHeader2,
      titleDialog
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
